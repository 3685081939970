export default [{
    path: '/merchant',
    component: () => import("@/views/Index.vue"),
    children: [{
        path: 'list',
        name: 'list',
        component: () => import('@/views/merchant/Index')
    }, {
        path: 'editSubMerchant',
        name: 'editSubMerchant',
        component: () => import('@/views/merchant/SubMerchantForm')
    }, {
        path: 'holdOrder',
        name: 'holdOrder',
        component: () => import('@/views/merchant/HoldOrder')
    }, {
        path: 'fundsFlow',
        name: 'fundsFlow',
        component: () => import('@/views/merchant/FundsFlow')
    }]
}]
