<template>
  <el-upload :disabled="loading" :headers="headers" :accept="accept" :on-success="onSuccess" :before-upload="beforeUpload" :with-credentials="true" :show-file-list="false" :action="apiBasePath+action">
    <el-button  type="primary" :loading="loading" icon="el-icon-upload2" style="margin：0 10px;">{{!name ? '点击上传' : name}}</el-button>
  </el-upload>
</template>

<script>
import config from "@/config"

export default {
  props: {
    accept: {
      type: String,
      require: true
    },
    action: {
      type: String,
      require: true
    },
    name: {
      type: String,
      require: false
    }
  },
  data () {
    let token = sessionStorage.getItem("_blue_whale_token_")
    return {
      apiBasePath: config.apiBasePath,
      headers: {
        Authorization: token
      },
      loading: false
    }
  },
  methods: {
    onSuccess (response, file, fileList) {
      this.$emit("success", response)
      this.loading = false
    },
    beforeUpload (file) {
      if (this.loading) {
        return false
      }
      this.loading = true
      this.$emit("beforeUpload",file)
      return true
    }
  }
}
</script>
