import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showSideMenu: false,
    userButtonList: []
  },
  mutations: {
    setShowSideMenu(state, isShow) {
      state.showSideMenu = isShow;
    },
    setUserButtonList (state, userButtonList) {
      state.userButtonList = userButtonList
    },
  },
  actions: {},
  modules: {
  }
});
