export default [{
    path: '/system',
    component: () => import("@/views/Index.vue"),
    children: [{
        path: 'role',
        name: 'role',
        component: () => import('@/views/role/Index'),
        meta: {
            title: '角色管理'
        }
    },
        {
            path: 'user',
            name: 'user',
            component: () => import('@/views/user/Index'),
            meta: {
                title: '用户管理'
            }
        },
        {
            path: 'module',
            name: 'module',
            component: () => import('@/views/module/Index'),
            meta: {
                title: '模块管理'
            }
        },
        {
            path: 'moduleNode/:moduleId',
            name: 'moduleNode',
            component: () => import('@/views/module/moduleNode'),
            meta: {
                title: '模块节点管理'
            }
        },
        {
            path: 'log',
            name: 'log',
            component: () => import('@/views/log/Index'),
            meta: {
                title: '操作日志'
            }
        }]
}]
