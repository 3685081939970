<template>
  <div>
    <el-table
      :data="tableData"
      @selection-change="handleSelectionChange"
      @expand-change="expandChange"
      stripe
      element-loading-text="数据正在拼命加载中..."
      border
      fit
      highlight-current-row
      v-loading="loading"
    >
      <slot></slot>
    </el-table>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="slot,sizes,prev,pager,next"
      :page-sizes="[10, 30, 50, 100]"
      :page-size="size"
      :total="total"
    >
      <span class="cost" v-if="cost">
        查询结果:{{ total }}条,查询耗时:{{ cost }}毫秒
      </span>
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: ["transCode", "params"],
  data() {
    return {
      tableData: null,
      loading: false,
      cost: null,
      current: 1,
      size: 10,
      total: 0
    };
  },
  watch: {
    loading(val) {
      this.$emit("update:loading", val);
    }
  },
  methods: {
    handleSizeChange(val) {
      this.size = val;
      this.load();
    },
    expandChange (row, expandedRows) {
      this.$emit("expand-change", row, expandedRows)
    },
    handleCurrentChange(val) {
      this.current = val;
      this.load();
    },
    handleSelectionChange(arg) {
      this.$emit("selection-change", arg);
    },
    async load() {
      const startTime = Date.now();
      this.loading = true;
      this.params.size = this.size
      this.params.current = this.current
      const rs = await this.$request(this.transCode, this.params);
      if (rs.status == 200) {
        this.tableData = rs.payload.records
        this.current = rs.payload.current
        this.size= rs.payload.size
        this.total= rs.payload.total || 0
        this.$emit("loaded", this.tableData, rs)
      }
      this.cost = Date.now() - startTime;
      this.loading = false;
    }
  },
  created() {
    this.load();
  }
};
</script>

<style lang="scss" scoped>
.el-pagination {
  padding: 25px 18px 10px;
  text-align: center;
  .btn-prev {
    margin-left: 0;
  }
  .cost {
    font-weight: normal;
    margin-right: 10px;
  }
  span{
    float: right;
  }
}
</style>
