<template>
    <el-upload
            class="upload-demo"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :action="apiBasePath + '/upload/originalFile'"
            :on-success="onSuccess"
            :on-exceed="onExceed"
            :limit="maxFileNum"
            :file-list="fileList">
        <el-button style="float: left;" size="small" type="primary">点击上传</el-button>
    </el-upload>
</template>

<script>
    import config from "@/config"

    export default {
        props: {
            value: {
                type: String,
                require: true
            },
            max: {
                type: Number,
                require: false
            }
        },
        data() {
            return {
                apiBasePath: config.apiBasePath,
                maxFileNum: this.max || 20,
                fileList: this.value ? JSON.parse(this.value) : [],
            }
        },
        methods: {
            handleRemove(file, fileList) {
                this.$emit("input", this.inputStr(fileList))
            },
            handlePreview(file) {
                // 点击文件下载
                let frame = document.querySelector("#downloadframe");
                if (frame == null) {
                    frame = document.createElement("iframe");
                    frame.setAttribute("id", "downloadframe");
                    frame.style.display = "none";
                    document.body.appendChild(frame)
                }
                console.log(file)
                frame.setAttribute("src", "https:" + file.url);
            },
            onExceed() {
                this.$message.error(`最多只能上传${this.maxFileNum}个文件`)
            },
            onSuccess(response, file, fileList) {
                if (response.status === 200) {
                    file.url = response.payload;
                    this.$emit("input", this.inputStr(fileList))
                } else {
                    this.$message.error(`文件上传失败:${response.msg}`)
                }
            },
            inputStr(fileList) {
                let str = JSON.stringify(fileList.map(item => {
                    return {url: item.url, name: item.name}
                }));
                console.log(str);
                return str;
            }
        }
    }
</script>
