import Vue from "vue";
import router from "./router";
import store from "./store";
import req from "./utils/request";
import {request} from "./utils/request";
import { GetQueryString } from './utils'
import Loading from "./components/Loading.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import PublicComponents from "./components/GolbalComponents";
import '@/styles/index.scss'; // global css
import AuthPlugin from "@/plugins/AuthPlugin";
import './icons'; // icon
import App from "./App.vue";
import echarts from "echarts";
import config from "./config";

Vue.prototype.$echarts = echarts
import md5 from 'js-md5'
Vue.prototype.$md5 = md5
Vue.use(ElementUI, {
  size: "mini"
});

Vue.use(PublicComponents);
Vue.use(AuthPlugin)
Vue.config.productionTip = false;

Vue.use(req);

// 优先取url中带的token,以便于用户切换
let token = GetQueryString("token")
if (token) {
  console.log(token)
  sessionStorage.setItem(config.tokenName, token);
  let rs = request("LoginHandle.getUserInfoByToken");
  if (rs.status === 200) {
    console.log(rs)
    sessionStorage.setItem(config.userInfoName, JSON.stringify(rs.payload));
  }
}

Vue.component("Loading", Loading);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
