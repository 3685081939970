import axios from "axios";
import config from "@/config";
import router from "@/router";
import {Message} from 'element-ui';

axios.defaults.baseURL = config.apiBasePath;

// request拦截器
axios.interceptors.request.use(
    success => {
        const token = sessionStorage.getItem(config.tokenName) || "";
        success.data.authorization = token;
        success.headers["Content-Type"] = "application/json";
        return success;
    },
    error => {
        return Promise.resolve({
            status: 500,
            error: "请求失败,请检查网络:" + error
        });
    }
);

// response拦截器
axios.interceptors.response.use(
    response => {
        const data = response.data;
        if (!data) {
            console.error("返回数据异常");
        }
        if (data.status === 403) {
            sessionStorage.removeItem(config.tokenName);
            if(isLoginPage(router.currentRoute.fullPath)){
                return data;
            }
            router.replace(
                "/login?redirect=" + encodeURIComponent(router.currentRoute.fullPath)
            );
            return data;
        }
        if (data.status !== 200 && data.status !== 201) {
            Message.error(data.msg || '')
        }
        return data;
    },
    error => {
        if (error && error.response) {
            if (error.response.status === 401) {
                sessionStorage.removeItem(config.tokenName);
                if(isLoginPage(router.currentRoute.fullPath)){
                    return error.response;
                }
                router.replace(
                    "/login?redirect=" + encodeURIComponent(router.currentRoute.fullPath)
                );
                return Promise.resolve({
                    status: 500,
                    error: "重新登录"
                });
            }
            if (error.response.status === 404) {
                return Promise.resolve({
                    status: 500,
                    error: "404接口路径不存在"
                });
            }
        }
        return Promise.resolve({
            status: 500,
            error: "请求处理失败"
        });
    }
);

function isLoginPage(currentRouter){
    return currentRouter.startsWith("/login");
}

export async function request(transCode, requestBody) {
    // 处理参数格式
    switch (typeof requestBody) {
        case 'object':
            requestBody = JSON.stringify(requestBody)
            break;
    }
    return await axios.post('/admin/httpRequest',
        {
            transCode: transCode,
            requestBody: requestBody || ''
        });
}

export default {
    install(Vue) {
        Vue.prototype.$request = request;
        Vue.prototype.$post = axios.post
        Vue.prototype.$get = axios.get
    }
};
