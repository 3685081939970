<template>
  <div>
    <slot :row="row" v-for="(row) in tableData"></slot>
    <div v-if="!loading&&tableData.length==0">
      未找到结果
    </div>
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      layout="slot,sizes,prev,pager,next" :page-sizes="[10, 30, 50, 100]" :page-size="pager.pageSize"
      :total="pager.total">
      <span class="cost" v-if="cost">
        查询结果:{{ pager.total }}条,查询耗时:{{ cost }}毫秒
      </span>
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: ["url", "params", "filter", "rowKey"],
  data() {
    return {
      tableData: null,
      loading: false,
      cost: null,
      pager: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      }
    };
  },
  watch: {
    loading(val) {
      this.$emit("update:loading", val);
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pager.pageSize = val;
      this.load();
    },
    handleCurrentChange(val) {
      this.pager.currentPage = val;
      this.load();
    },
    handleSelectionChange(selection) {
      if (!this.loading) {
        this.$emit("selection-change", selection, this.tableData);
      }
    },
    async load() {
      const startTime = Date.now();
      this.loading = true;
      const rs = await this.$post(this.url, {
        params: this.params,
        pager: this.pager
      });
      if (rs.status == 200) {
        if (this.filter) {
          this.tableData = this.filter(rs.payload.records);
        } else {
          this.tableData = rs.payload.records;
        }
        this.pager = {
          currentPage: rs.payload.current,
          pageSize: rs.payload.size,
          total: rs.payload.total
        };
      }
      this.cost = Date.now() - startTime;
      this.$nextTick(() => {
        this.$emit("loaded", this.tableData);
        this.loading = false;
      });
    }
  },
  created() {
    this.load();
  }
};
</script>

<style lang="scss" scoped>
.el-pagination {
  margin-top: 10px;
  padding-left: 0;
  .btn-prev {
    margin-left: 0;
  }
  .cost {
    font-weight: normal;
    margin-right: 10px;
  }
}
</style>
