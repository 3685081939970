import Vue from "vue";
import VueRouter from "vue-router";
import config from "@/config";
import store from "@/store"
import supplier from './supplier'
import system from './system'
import product from './product'
import order from './order'
import merchant from './merchant'
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
};

const routes = [
  {
    path: '/',
    component: () => import("@/views/Home.vue"),
    children: [{
        path: "",
        redirect: "dashboard"
      },
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("@/views/dashboard/Index.vue")
      }
    ].concat(system,supplier,product,order,merchant)
  },
  {
    path: "/login",
    name: "login",
    meta: {
      free: true
    },
    component: () => import("@/views/login/Index.vue")
  },
  {
    path: "/qrLogin",
    name: "qrLogin",
    meta: {
      free: true
    },
    component: () => import('@/views/qrlogin/Index')
  },
  {
    path: "/bindLogin",
    name: "bindLogin",
    meta: {
      free: true
    },
    component: () => import('@/views/bindLogin/Index')
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  const meta = to.meta;
  const token = sessionStorage.getItem(config.tokenName);
  // 判断是否已在登录页
  const currentLoginPage = to.path.startsWith("/login");
  if ((!meta || !meta.free) && !token && !currentLoginPage) {
    next("/login?redirect=" + encodeURIComponent(to.path));
    return;
  }
  if (meta && meta.hideSideMenu) {
    store.commit("setShowSideMenu", false);
  } else {
    store.commit("setShowSideMenu", true);
  }
  next();
});

export default router;
