<template>
  <div>
    <div class="menu-name" :class="{ 'menu-name-active': $route.path == menu.router }" @click="onMenuClick">
       <i :class="menu.icon || 'el-icon-menu'"></i>
      {{ menu.name }}
      <i v-if="hasChildren && !menu.isLeaf" class="arrow el-icon-caret-bottom"
        :class="{ 'arrow-ref': showChildren }"></i>
    </div>
    <div class="menu-children" v-if="showChildren && hasChildren">
      <MenuItem v-for="m in menu.subList" :key="m.id" :menu="m" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["menu"],
  name: "MenuItem",
  data() {
    return {
      showChildren: false,
      hasChildren: this.menu.subList && this.menu.subList.length > 0
    };
  },
  methods: {
    onMenuClick() {
      if (
        this.menu.isLeaf &&
        this.$route.path != this.menu.router &&
        this.menu.router
      ) {
        this.$router.push(this.menu.router);
      }else {
        this.showChildren = !this.showChildren;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-name {
  position: relative;
  height: 46px;
  line-height: 46px;
  padding: 0 20px;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  transition: all 0.2s;
  &:hover {
    background: #eef5fe;
    color:#333;
  }
  .arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -9px;
    transition: all 0.3s;
  }
  .arrow-ref {
    transform: rotate(180deg);
  }
}
.menu-name-active {
  color: #333;
  background: #ecf3fe;
  /*&::before {*/
  /*  content: " ";*/
  /*  position: absolute;*/
  /*  top: 0;*/
  /*  left: 2px;*/
  /*  bottom: 0;*/
  /*  border-left: 6px solid #498bf7;*/
  /*}*/
}
.menu-children {
  .menu-name {
    padding-left: 40px;
  }
}
</style>
