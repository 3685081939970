export default [{
    path: '/product',
    component: () => import("@/views/Index.vue"),
    children: [{
        path: 'category',
        name: 'category',
        component: () => import('@/views/product/Category')
        },
        {
            path: 'list',
            name: 'list',
            component: () => import('@/views/product/Index')
        },
        {
            path: 'skus/:proId/:proType',
            name: 'skus',
            component: () => import('@/views/product/Skus')
        },
        {
            path: 'supplierRelation/:skuId',
            name: 'supplierRelation',
            component: () => import('@/views/product/SupplierRelations')
        }]
    },
    {
        path: '/card',
        component: () => import("@/views/Index.vue"),
        children: [{
            path: 'importCard',
            name: 'importCard',
            component: () => import("@/views/supplier/ImportCard.vue")
        },
            {
                path: 'records',
                name: 'records',
                component: () => import('@/views/supplier/ImportRecords')

            }
        ]
    }]
