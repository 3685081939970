import PaginateTable from "./PaginateTable";
import PaginateTableNoPage from "./PaginateTableNoPage";
import PaginateList from "./PaginateList";
import MainContent from "./MainContent";
import MenuItem from "./MenuItem";
import LayoutSearch from "./LayoutSearch";
import LayoutDialog from "./LayoutDialog";
import UploadButton from "./UploadButton";
import UploadFileList from "../Upload/UploadFileList";

export default {
  install(Vue) {
    Vue.component("PaginateTable", PaginateTable);
    Vue.component("PaginateTableNoPage", PaginateTableNoPage);
    Vue.component("PaginateList", PaginateList);
    Vue.component("MainContent", MainContent);
    Vue.component("MenuItem", MenuItem);
    Vue.component("LayoutSearch", LayoutSearch);
    Vue.component("LayoutDialog", LayoutDialog);
    Vue.component("UploadButton", UploadButton);
    Vue.component("UploadFileList", UploadFileList);
  }
};
