let AuthPlugin = {}
AuthPlugin.install = function (Vue, options) {
  // 实例方法
  Vue.prototype.$hasAuth = function (btn) {
    let modules = this.$store.state.userButtonList
    return modules.includes(btn);
  }

  Vue.prototype.$checkPassWord = async function (password) {
    return new Promise((resolve, reject) => {
      this.$prompt("请输入密码以继续", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /.+/,
        inputType: "password",
        inputErrorMessage: "请输入密码"
      })
        .then(async ({ value }) => {
          let rs = await this.$request("SystemManageHandle.checkUserPwd",value);
          if (rs.status == 200) {
            resolve(true)
          } else {
            resolve(false)
          }
        })
        .catch(() => {
          resolve(false)
        })
    })
  }
}
export default AuthPlugin
