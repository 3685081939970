export default [{
    path: '/order',
    component: () => import("@/views/Index.vue"),
    children: [{
        path: 'list',
        name: 'list',
        component: () => import('@/views/order/Index')
        },
        {
            path: 'merchantOrder',
            name: 'merchantOrder',
            component: () => import('@/views/order/MerchantOrder')
        },
        {
            path: 'manualOrder',
            name: 'manualOrder',
            component: () => import('@/views/order/ManualOrder')
        }]
}]