<template>
  <div class="loading" :class="{ 'loading-block': type == 'block' }">
    <div class="icon">&#xe64f;</div>
    <div class="text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["type"]
};
</script>

<style lang="scss" scoped>
.loading {
  display: inline-block;
  vertical-align: middle;
  height: 30px;
  line-height: 30px;
  .icon {
    display: inline-block;
    vertical-align: middle;
    height: 30px;
    line-height: 30px;
    width: 30px;
    text-align: center;
    font-size: 20px;
    animation: rotate 3s infinite linear;
    font-family: iconfont;
  }
  .text {
    display: inline-block;
    vertical-align: middle;
    height: 30px;
    line-height: 30px;
  }
}
.loading-block {
  display: block;
  margin: 100px auto;
  text-align: center;
}
@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
