<template>
  <div>
    <el-table
      :data="tableData"
      @selection-change="handleSelectionChange"
      stripe
      element-loading-text="数据正在拼命加载中..."
      border
      fit
      highlight-current-row
      v-loading="loading"
    >
      <slot></slot>
    </el-table>
  </div>
</template>

<script>
  export default {
    props: ["transCode", "params","label","dataCount"],
    data() {
      return {
        tableData: null,
        loading: true
      };
    },
    watch: {
      loading(val) {
        this.$emit("update:loading", val);
      }
    },
    methods: {
      handleSizeChange(val) {
        this.size = val;
        this.load();
      },
      handleCurrentChange(val) {
        this.current = val;
        this.load();
      },
      handleSelectionChange(arg) {
        this.$emit("selection-change", arg);
      },
      async load() {
        this.loading = true;
        const rs = await this.$request(this.transCode, this.params);
        if (rs.status == 200) {
          this.tableData = rs.payload;
          if (this.dataCount) {
            this.dataCount.count = this.tableData ? this.tableData.length : 0;
          }
        }
        this.loading = false;
      }
    },
    created() {
      this.load();
    }
  };
</script>

<style lang="scss" scoped>
.el-pagination {
  padding: 25px 18px 10px;
  text-align: center;
  .btn-prev {
    margin-left: 0;
  }
  .cost {
    font-weight: normal;
    margin-right: 10px;
  }
  span{
    float: right;
  }
}
</style>
