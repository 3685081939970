<template>
<div class="outside">
  <div class="content">
    <div class="content-header" v-if="title || showBack">
      <div class="content-title">{{ title }}</div>
      <div class="content-back" v-if="showBack" @click="$router.go(-1)">
        <i class="el-icon-arrow-left" />返回
      </div>
    </div>
    <slot></slot>
  </div>
</div>
</template>

<script>
export default {
  props: ["back", "title"],
  data() {
    return {
      showBack: this.back != undefined
    };
  }
};
</script>

<style lang="scss" scoped>
.outside{
  //background: #F6F8F9;
  padding: 20px;
  min-height: 100%;
}
.content {
  padding: 20px;
  min-height: 300px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  background: #fff;
  border-radius: 5px;
  .content-header {
    position: relative;
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-bottom: 40px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    .content-title {
      display: inline-block;
      vertical-align: middle;
    }
    .content-back {
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
      font-size: 12px;
      height: 20px;
      line-height: 20px;
      border: 1px solid #ccc;
      padding: 0 10px;
    }
    &::before {
      content: " ";
      position: absolute;
      top: 5px;
      left: 0;
      bottom: 15px;
      width: 5px;
      background: #409eff;
    }
  }
}
</style>
