export default [{
    path: '/supplier',
    component: () => import("@/views/Index.vue"),
    children: [{
        path: 'list',
        name: 'list',
        component: () => import('@/views/supplier/Index')
        },
        {
            path: 'records',
            name: 'records',
            component: () => import('@/views/supplier/RemitRecord')

        }
    ]
    },
    {
        path: '/coupon',
        component: () => import("@/views/Index.vue"),
        children: [{
            path: 'extract',
            name: 'extract',
            component: () => import('@/views/coupon/Index')
        },
            {
                path: 'records',
                name: 'records',
                component: () => import('@/views/coupon/Records')

            }
        ]
    },
    {
        path: '/recharge',
        component: () => import("@/views/Index.vue"),
        children: [{
            path: 'online',
            name: 'online',
            component: () => import('@/views/recharge/Index')
        },
            {
                path: 'records',
                name: 'records',
                component: () => import('@/views/recharge/Records')

            }
        ]
    }]